import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';

import ProtectedRoute from './auth/ProtectedRoute';
import { Loading } from './components';

const Incident = lazy(() => import(/* webpackChunkName: "Incident" */ './pages/Incident/Incident'));

const IncidentOverview = lazy(() => import(/* webpackChunkName: "IncidentOverview" */ './pages/IncidentOverview/IncidentOverview'));

const IncidentOutreach = lazy(() => import(/* webpackChunkName: "IncidentOutreach" */ './pages/IncidentOutreach/IncidentOutreach'));

const TPIRVendors = lazy(() => import(/* webpackChunkName: "TPIRVendors" */ './pages/TPIRVendors/TPIRVendors'));

const TPIRVendorsList = lazy(() => import(/* webpackChunkName: "TPIRVendorsList" */ './pages/TPIRVendors/TPIRVendorList/TPIRVendorsList'));

const IncidentCampaign = lazy(() => import(/* webpackChunkName: "IncidentCampaign" */ './pages/IncidentCampaign/IncidentCampaign'));

const Assessment = lazy(() => import(/* webpackChunkName: "Assessment" */ './pages/Assessment/Assessment'));
const AssessmentRequest = lazy(() => import(/* webpackChunkName: "AssessmentRequest" */ './pages/Assessment/AssessmentRequest/AssessmentRequest'));

const ClientProfile = lazy(() => import(/* webpackChunkName: "ClientProfile" */ './pages/ClientProfile/ClientProfile'));

const Session = lazy(() => import(/* webpackChunkName: "Session" */ './pages/Session/Session'));

const Logout = lazy(() => import(/* webpackChunkName: "Logout" */ './pages/Logout/Logout'));

const Unauthorized = lazy(() => import(/* webpackChunkName: "Unauthorized" */ './pages/Unauthorized/Unauthorized'));
const Error = lazy(() => import(/* webpackChunkName: "Error" */ './pages/Error/Error'));

const Profile = lazy(() => import(/* webpackChunkName: "profile" */ './views/profile'));

const AssessmentsOverview = lazy(() => import(/* webpackChunkName: "Assessment" */ './pages/AssessmentsOverview'));

const ProgramMetrics = lazy(() => import(/* webpackChunkName: "ProgramMetrics" */ './pages/ProgramMetrics/ProgramMetrics'));

const SupportTicket = lazy(() => import(/* webpackChunkName: "SupportTicket" */ './pages/SupportTicket/SupportTicketOverview'));

const SupportTicketDetails = lazy(() => import(/* webpackChunkName: "SupportTicketDetails" */ './pages/SupportTicket/SupportTicketDetails'));

const ExecutiveSummary = lazy(() => import(/* webpackChunkName: "ExecutiveSummary" */ './pages/ExecutiveSummary'));

const SupportArticles = lazy(() => import(/* webpackChunkName: "SupportArticles" */ './pages/SupportTicket/SupportArticles'));

const AccessDenied = lazy(() => import(/* webpackChunkName: "AccessDenied" */ './components/AccessDenied'));

const DynamicVendorRoster = lazy(() => import(/* webpackChunkName: "DynamicVendorRoster" */ './pages/DynamicVendorRoster/DynamicVendorRoster'));

const VendorsAndProducts = lazy(() => import(/* webpackChunkName: "VendorsAndProducts" */ './pages/VendorsAndProducts'));


const AddVendorsAndProducts = lazy(() => import(/* webpackChunkName: "AddVendorsAndProducts" */ './pages/VendorsAndProducts/AddVendorsAndProducts'));

const RemediationGuidance = lazy(() => import(/* webpackChunkName: "RemediationGuidance" */ './pages/RemediationGuidance'));

const DeveloperPortal = lazy(() => import(/* webpackChunkName: "DeveloperPortal" */ './pages/SupportTicket/DeveloperPortal/DeveloperPortal'));

const ApiKeyManager = lazy(() => import(/* webpackChunkName: "ApiKeyManager" */ './pages/ApiKeyManager/ApiKeyManager'));

const APIDocumentation = lazy(() => import(/* webpackChunkName: "APIDocumentation" */ './pages/SupportTicket/DeveloperPortal/APIDocumentation/APIDocumentation'));

const AssessmentResponses = lazy(() => import(/* webpackChunkName: "AssessmentResponses*/ 'pages/AssessmentResponses'));

const AssessmentDetailsPage = lazy(() => import(/* webpackChunkName: "AssessmentDetailsPage*/ 'pages/AssessmentsOverview/AssessmentDetails/AssessmentDetailsPage'));

const CommunicationPreferences = lazy(() => import(/* webpackChunkName: "CommunicationPreferences*/ 'pages/CommunicationPreferences'));

const VendorCatalog = lazy(() => import(/* webpackChunkName: "VendorCatalog" */ './pages/VendorCatalog'));

const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ './pages/Dashboard/Dashboard'));

const ManagementDashboard = lazy(() => import(/* webpackChunkName: "ManagementDashboard" */ './pages/ManagementDashboard/ManagementDashboard'));

const RemediationPage = lazy(() => import('./pages/Remediation/RemediationPage'));

export default function Router() {
  return (
    <Suspense fallback={<Loading />}>
      <ProtectedRoute path="/" exact component={Dashboard} />
      <ProtectedRoute path="/command-center" exact component={Dashboard} />
      <ProtectedRoute path="/incident" exact component={Incident} />
      <ProtectedRoute path="/incident-overview" exact component={IncidentOverview} />
      <ProtectedRoute path="/tpir-vendors" exact component={TPIRVendors} />
      <ProtectedRoute path="/incident-outreach/:id" exact component={IncidentOutreach} />
      <ProtectedRoute path="/vendor-list" exact component={TPIRVendorsList} />
      <ProtectedRoute path="/incident-campaign" exact component={IncidentCampaign} />
      <ProtectedRoute path="/assessment" exact component={Assessment} />
      <ProtectedRoute path="/assessment-request" exact component={AssessmentRequest} />
      <ProtectedRoute path="/client-profile" component={ClientProfile} />
      <ProtectedRoute path="/assessments-overview" exact component={AssessmentsOverview} />
      <ProtectedRoute path="/program-metrics" exact component={ProgramMetrics} />
      <ProtectedRoute path="/dynamic-vendor-roster" exact component={DynamicVendorRoster} />
      <ProtectedRoute path="/support" exact component={SupportTicket} />
      <ProtectedRoute path="/executive-summary/:id" exact component={ExecutiveSummary} />
      <ProtectedRoute path="/profile" component={Profile} />
      <Route path="/session" component={Session} />
      <Route path="/logout" component={Logout} />
      <Route path="/unauthorized" component={Unauthorized} />
      <Route path="/error" component={Error} />
      <Route path="/access-denied" component={AccessDenied} />
      <ProtectedRoute path="/support-ticket-details/:id" exact component={SupportTicketDetails} />
      <ProtectedRoute path="/support-articles" exact component={SupportArticles} />
      <ProtectedRoute path="/vendors-and-products" exact component={VendorsAndProducts} />
      <ProtectedRoute path="/add-vendors-and-products" exact component={AddVendorsAndProducts} />
      <ProtectedRoute path="/remediation-guidance/:id" exact component={RemediationGuidance} />
      <ProtectedRoute path="/developer-portal" exact component={DeveloperPortal} />
      <ProtectedRoute path="/assessment-responses/:id" exact component={AssessmentResponses} />
      <ProtectedRoute path="/api-key-manager" exact component={ApiKeyManager} />
      <ProtectedRoute path="/api-documentation" exact component={APIDocumentation} />
      <ProtectedRoute path="/assessment-details/:id" exact component={AssessmentDetailsPage} />
      <ProtectedRoute path="/communication-preferences" exact component={CommunicationPreferences} />
      <ProtectedRoute path="/vendor-catalog" exact component={VendorCatalog} />
      <ProtectedRoute path="/management-dashboard" exact component={ManagementDashboard} />
      <ProtectedRoute path="/remediation" exact component={RemediationPage} />
    </Suspense>
  );
}
